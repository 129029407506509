<template>
   <TodoList />
</template>

<script>
import TodoList from "./components/TodoList.vue";

export default {
   name: "App",
   components: {
      TodoList,
   },
};
</script>

<style>
body {
   background-color: #247ba0;
   color: #cd5d67;
}
</style>
